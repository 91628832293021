.chip {
  display: inline-flex;
  background: $teal5;
  position: relative;

  // height: 37px;
  padding: 10px 14px;
  margin: 5px;

  font-size: 16px;

  border-radius: 24px;

  align-items: center;

  span {
    margin-right: 0.5rem;
  }
}

#chip-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;

  // height: 100%;

  font-size: 15px;
  line-height: 1.8rem;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: bold;

  svg {
    height: 20px;
    cursor: pointer;
  }
}

.qtdClear {
  display: flex;
  align-items: center;
  p {
    color: $gray2;
  }
}
