* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
  vertical-align: baseline;
}

body {
  // Core configurations
  @import './core/colors';

  // Components - Elements
  @import './elements/button';
  @import './elements/input';
  @import './elements/form';
  @import './elements/select';
  @import './elements/summary';
  @import './elements/chip';
  @import './elements/chart';
  @import './elements/imagePreview';
  @import './elements/dragndrop';
  @import './elements/selectedfield';
  @import './elements/checked';
  @import './elements/imc';
  @import './elements/modal';
  @import './elements/doctemplate';
  @import './elements/loader';

  // Layouts
  @import './layouts/main';
  @import './layouts/header';
  @import './layouts/nav';
  @import './layouts/exams';
  @import './layouts/anamnesis';
  @import './layouts/karmen';

  background: $gray4;

  #root {
    background: $gray4;

    font-family: Barlow;
  }

  #memed-container {
    position: relative;
  }

  p,
  span,
  a,
  li {
    font-family: Lato;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Titillium Web;
    font-weight: 600;
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .video.stuck {
    position: fixed;
    bottom: 5px;
    left: 5px;
    width: 400px;
    height: 300px;
    transform: translateY(100%);
    animation: fade-in-up 0.5s ease forwards;
    z-index: 1;
  }
}
