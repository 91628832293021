.monitoring {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px 0;

  .monitoring-div {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      text-align: center;
      font-weight: 500;
      font-size: 17px;
      padding-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      #monitoring-input {
        padding: 9px 0;
        width: 100%;
      }
      .item + .item {
        margin-top: 10px;
      }

      .trash-can {
        zoom: 1.1;
        cursor: pointer;
        fill: $gray3;

        margin: 0 0 0 18px;
        width: 100px;

        &:hover {
          fill: $gray2;
        }
      }

      .trash-can-notification {
        zoom: 1.1;
        cursor: pointer;
        fill: $gray3;

        margin: 0 18px;

        &:hover {
          fill: $gray2;
        }
      }
    }
  }

  button {
    max-width: 400px;
    margin: 0;
    padding: 5px 15px;
  }
}

#monitoring-select-div {
  width: 40% !important;
}

.monitoring-select {
  margin-top: 0 !important;
}

.especialidade-container {
  div:not(:first-child) {
    margin-top: 1rem;
  }
}

.dados {
  display: flex;
}
