.image-preview {
  display: flex;
  align-items: center;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 130px;
    height: 110px;

    // padding: 8px;
    border: 0.1rem solid lighten($gray3, 20%);
    border-radius: 0.8rem;

    margin: 0 1rem;

    img {
      height: 90px;
      width: 110px;
      object-fit: cover;
    }

    .image-clear {
      display: flex;
      align-items: center;
      position: absolute;
      cursor: pointer;

      left: -7px;
      top: -7px;

      fill: $white1;
      background: $gray3;

      border-radius: 50%;

      opacity: 0.9;

      svg {
        zoom: 1.1;
        height: 25px;
      }

      z-index: 2;
    }

    .check {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      height: 100%;
      width: 100%;

      border-radius: 10px;

      color: $white1;

      span {
        display: none;

        border: 1px solid $white1;
        padding: 0 10px;

        font-size: 20px;

        cursor: pointer;
      }

      transition: all 0.2s;

      &:hover {
        background: #303030a1;

        span {
          display: inline;
        }
      }
    }
  }

  .input-outside {
    width: 100%;
  }
}
