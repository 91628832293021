.imc {
  text-align: center;
}

.summary-imc {
  padding: 5px 10px;
  margin: 5px 0;
  background: $green2;
  border-radius: 4px;

  color: $white1;

  p {
    padding: 0 !important;
    font-size: 18px !important;
  }
}
