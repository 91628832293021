#nav-content {
  @media (min-width: 700px) {
    transform: translateX(0);
    position: relative;

    .nav-items {
      height: 100%;
      transform: translateX(0);
      text-decoration: none;
      display: flex;
      flex-direction: column;

      ul {
        display: flex;
        list-style: none;
        flex-direction: column;

        text-align: center;
        padding: 0;

        .open-memed {
          padding: 15px 30px !important;
          color: $gray1;
          font-size: 18px;

          cursor: pointer;

          &:hover {
            color: $teal1;
          }
        }

        li {
          border-top: rgba($color: $teal3, $alpha: 0.3) 1px solid;

          &:last-child {
            border-bottom: rgba($color: $teal3, $alpha: 0.3) 1px solid;
          }

          transition: all 0.2s;

          &:hover {
            background-color: $teal7;
          }

          a,
          button {
            display: block;
            color: $gray1;
            font-size: 18px;
            padding: 15px 30px !important;

            &:hover {
              color: $teal1;
            }
          }

          button {
            width: 100%;
            font-weight: 500;
            color: $gray3;
            background-color: $gray4;

            &:hover {
              color: $primaryPink4;
              background-color: $primaryPink6;
            }
          }
        }
      }
    }
  }
}

.nav-content-open {
  display: flex;
  position: fixed;
  min-height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateX(0);
  opacity: 1;
  z-index: 2;
  transition: 0.4s opacity ease-in-out;

  .nav-items {
    height: 100%;
    transform: translateX(0);
    background-color: white;
    transition: 0.4s transform ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      flex-direction: column;
      padding-top: 5rem;

      .open-memed {
        padding: 15px 30px !important;
        color: $gray1;
        font-size: 18px;

        cursor: pointer;

        &:hover {
          color: $teal1;
        }
      }

      li {
        a,
        button {
          display: block;
          font-size: 18px;
          color: $gray1;
          padding: 12px 30px !important;
        }

        button {
          width: 100%;
          color: $gray3;
          background-color: $gray4;
          font-weight: 500;
        }
      }
    }
  }
}

.nav-content-close {
  transform: translateX(-150%);
  position: fixed;
  transition: transform 0.4s;
  top: 0;
  left: 0;

  .nav-items {
    transform: translateX(-100%);
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 1s;

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      flex-direction: column;
      padding-top: 5rem;

      li {
        a,
        button {
          display: block;
          font-size: 18px;
          color: $gray1;
          padding: 12px 40px !important;
        }

        button {
          width: 100%;
          font-weight: 500;
          color: $gray3;
          background-color: $gray4;
        }
      }
    }
  }
}
