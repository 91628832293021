.header {
  background-color: #fff;
  height: fit-content;

  .header-menu {
    gap: 16px;
    display: flex;
    align-items: center;

    svg {
      zoom: 1.5;
      fill: $primaryPink3;
      cursor: pointer;
      z-index: 10;
    }
  }

  .more-info {
    text-align: center;
    margin: 15px 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      cursor: pointer;
      padding: 5px 20px;
    }

    > div {
      ul {
        text-align: center;

        li {
          padding: 8px 10px;
          border-top: 1px solid $green5;
          background: lighten($green5, 17%);

          &:last-child {
            border-bottom: 1px solid $green5;
          }
        }
      }
    }
  }
}

.burguer-close {
  position: relative;
  z-index: 3;
  transition: position 1s;
}

.burguer-open {
  position: fixed;
  transition: position 1s;
  top: 30px;
  left: 30px;
  z-index: 3;
}

@media (min-width: 700px) {
  .burguer-close,
  .burguer-open {
    display: none;
  }
}
