.border-outline-karmen {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.border-12 {
  border-radius: 12px;
}

.border-rb-12 {
  border-radius: 0px 0px 12px 0px;
}

.border-lb-12 {
  border-radius: 0px 0px 0px 12px;
}

.border-r-12 {
  border-radius: 0px 12px 12px 0px;
}

.h--48 {
  height: 48px;
}

.text-negative {
  color: #b00020;
}

.anamnese-container {
  background-color: rgba(251, 251, 251, var(--tw-bg-opacity));
}

.font-lato {
  font-family: Lato;
}

.text-color-m-emphase {
  color: rgba(0, 0, 0, 0.6);
}

.text-color-h-emphase {
  color: rgba(0, 0, 0, 0.87);
}

.tracking-7 {
  letter-spacing: 0.07em;
}
