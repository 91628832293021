@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-min-content {
    width: min-content;
  }
}

@import '../../node_modules/tippy.js/dist/tippy.css';
@import '../../node_modules/tippy.js/themes/light.css';

:root {
  --height-header-footer: 203px;
  --sm: 425px;
  --md: 768px;
  --lg: 959px;
  --xl: 1024px;
}

html,
body {
  height: 100%;
}

input,
textarea {
  font-family: 'Lato';
}

/**
 *  Edited to remove some unused styles.
 */
header.masthead {
  position: relative;
  background-color: #343a40;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0 5px 5px 0 #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.full-height-wrapper {
  min-height: 100%;
  margin-bottom: -8rem;
}

.full-height-push {
  height: 8rem;
}

.page-container {
  min-height: 100%;
}

main {
  min-height: calc(100% - var(--height-header-footer));
  display: flex !important;
  justify-content: center;
  align-content: center;
}

footer.footer {
  background-color: #243e42 !important;
  color: #fff;
  height: 8rem;
  padding-top: 18px;
}

a {
  color: #d92b68;
}

footer.footer a {
  color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #d92b68;
  border-color: #d92b68;
}

.btn-primary:hover {
  color: #fff;
  background-color: #a52a52;
  border-color: #a52a52;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
.select-dropdown {
  position: relative;
  background-color: #d20e50;
  border-radius: 4px;

  border-radius: 0.375rem 0 0 0.375rem;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
}
.select-dropdown select {
  font-size: 1rem;
  font-weight: 500;
  color: #fbfbfb;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: #d20e50;
  border-radius: 0.375rem 0 0 0.375rem;
}
.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}
.select-dropdown:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #fbfbfb;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

form {
  margin-top: 0em;
  margin-block-end: 0em;
}

.content-password-zxcvbn {
  position: relative;
  width: 70%;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}

.strength-meter:before,
.strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}

.strength-meter:before {
  left: calc(20% - 3px);
}

.strength-meter:after {
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
  width: 20%;
  background: darkred;
}

.strength-meter-fill[data-strength='1'] {
  width: 40%;
  background: orangered;
}

.strength-meter-fill[data-strength='2'] {
  width: 60%;
  background: orange;
}

.strength-meter-fill[data-strength='3'] {
  width: 80%;
  background: yellowgreen;
}

.strength-meter-fill[data-strength='4'] {
  width: 100%;
  background: green;
}

.button {
  border: 2px solid #d20e50;
}

.selectSpecialty {
  box-shadow: 0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.assina-shadow-sm {
  box-shadow: 0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05);
}

.select-appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 750px) {
  .button-temporary-password {
    margin-top: 6rem;
  }
  .content-message {
    margin: 0;
  }
}

.circle-before {
  position: relative;
}

.circle-before::before {
  content: '';
  position: absolute;
  display: inline-block;
  top: 11px;
  left: -10px;
  width: 4px;
  height: 4px;
  border-radius: 7.5px;
  background-color: #d20e50;
}

#dashboard-column-scrollbar::-webkit-scrollbar {
  width: 6px;
}

#dashboard-column-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 30px;
}

#dialog-scrollbar::-webkit-scrollbar {
  width: 6px;
}

#dialog-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 30px;
}
