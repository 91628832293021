.title-block-input {
  padding-left: 0.8rem;
  color: &gray1;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.input-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-main {
    display: flex;
    align-items: center;
    width: 100%;

    #input-bio {
      width: 100%;
    }

    p {
      padding: 35px 0 0 8px;
      font-size: 19px;
      line-height: 22px;
      color: $gray1;
    }
  }
}

.input-outside {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .input-body {
    display: inline-flex;

    background: $gray5;
    border-radius: 0.4rem;
    border: 0.01rem solid $white1;

    ::placeholder {
      color: $gray3;
    }

    transition: all 0.1s ease;

    &:focus-within {
      border: 0.1px solid $primaryPink4;
      box-shadow: 0 0 1px 2.5px $primaryPink5;
    }

    .input {
      background: none;
      border: none;

      width: 100%;

      font-weight: 500;
      color: $gray1;
      font-size: 16px;

      padding: 15px 10px 16px;

      &:focus {
        outline: none;
      }
    }

    textarea {
      resize: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .has-icon {
    position: relative;

    svg {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.4rem;
      fill: $gray3;
    }
  }
}

.input-label {
  font-weight: 500;
  line-height: 1.7rem;
  color: $gray1;
  font-size: 17px;
}
