.form {
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.6rem 0.6rem;

  margin: 16px 0;
  margin-bottom: 180px;
  width: 100%;
  height: fit-content;

  .form-header {
    background-color: $teal5;
    padding: 12px;
    margin: 0 16px;
    border-radius: 4px 4px 0 0;
    color: $teal2;

    h1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.7rem;
    }
  }

  .form-body {
    margin: 0 16px 16px;
    padding: 12px;
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;

    height: 100%;

    .form-error {
      color: $red3;
    }
    .form-footer {
      padding-top: 10px;
      border-top: 1px solid lighten($gray3, 10%);

      .form-status {
        position: relative;
        margin-top: 0.4rem;
        align-items: center;
        height: 4rem;
        display: flex;

        button {
          position: absolute;
          top: 0;
          right: 0;
          width: 150px;
          padding: 7px 16px;
        }

        .form-saved {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding-bottom: 1rem;
          width: calc(100% - 150px);

          h1 {
            margin-bottom: 0.5rem;
          }

          svg {
            zoom: 1.5;
          }
        }

        .form-not-saved {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding-bottom: 1rem;
          width: calc(100% - 150px);

          h1 {
            margin-bottom: 0.5rem;
          }

          svg {
            zoom: 1.5;
            fill: $red3;
          }
        }
      }
    }
  }
}

.print-document {
  svg {
    height: 20px;
    margin-right: 2px;
    fill: lighten($gray2, 5%);
  }

  .button-print {
    padding: 4px;
    max-width: 120px;

    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .form-saved,
  .form-not-saved {
    svg {
      color: $red3;
    }
  }

  .form-status {
    display: flex;
    gap: 1rem;
  }
}
