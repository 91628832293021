.drag-n-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 20rem;

  border: 0.2rem dashed $gray3;

  margin: 1rem 0;
  padding: 15px;

  cursor: pointer;

  text-align: center;

  &:focus {
    outline: none;
  }

  h2 {
    margin-bottom: 30px;
    margin-top: -15px;

    font-size: 20px;
    font-weight: 500;
  }

  .input-button {
    padding: 16px 26px;

    border-radius: 0.4rem;

    font-size: 17px;
    font-weight: 500;

    background-color: $gray6;
    border: 0.199rem solid lighten($gray3, 10%);
    color: $gray2;

    transition: all 0.15s;

    &:hover {
      background: darken($gray6, 2%);
    }

    &:focus {
      background: lighten($gray6, 3%);
      outline: none;
    }
  }
}

input[type='file'] {
  display: none;
}
