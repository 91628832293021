.selected {
  .selected-header {
    background: $green5;
    padding: 10px 8px;
    border-radius: 0.6rem 0.6rem 0 0;
    color: $gray2;

    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.7rem;
    }
  }

  .selected-body {
    padding: 10px;
    background: $gray5;

    p {
      font-weight: 500;
      font-size: 15px;
      padding: 1rem 0;
    }
  }
}
