.button {
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  cursor: pointer;

  padding: 12px 20px;
  margin: 0 0 0.8rem 0;

  width: 100%;

  background: none;
  outline: none;
  border: none;

  border-radius: 4px;

  font-size: 18px;
  line-height: 2.2rem;
  font-weight: 500;
}

.is-outlined {
  color: $primaryPink3;
  background: $gray6;

  transition: all 0.15s;

  border: 0.199rem solid $gray3;

  &:hover {
    background: darken($gray6, 2%);
  }

  &:focus {
    background: lighten($gray6, 3%);
  }
}

.button-primary {
  color: $gray5;
  background: $primaryPink3;

  transition: all 0.15s;

  &:hover {
    background: darken($primaryPink3, 5%);
  }

  &:focus {
    background: lighten($primaryPink3, 3%);
  }
}

.button-green {
  color: $gray5;
  background: $teal2;

  &:hover {
    background: darken($teal2, 2%);
  }

  &:focus {
    background: lighten($teal2, 3%);
  }
}

.button-lightgreen {
  color: $gray5;
  background: $green2;

  &:hover {
    background: darken($green2, 2%);
  }

  &:focus {
    background: lighten($green2, 3%);
  }
}

.button-white {
  color: $gray2;
  background: $gray6;

  transition: all 0.15s;

  border: 1px solid lighten($gray3, 10%);

  &:hover {
    background: darken($gray6, 7%);
  }

  &:focus {
    background: lighten($gray6, 3%);
  }
}

.button-disabled {
  color: $gray2;
  background: $gray6;

  transition: all 0.15s;

  border: 1px solid lighten($gray3, 10%);
}

.header-button {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  margin: 20px 0 0;

  fill: $gray5;

  svg {
    margin-right: 10px;
  }
}

.div-button-inicial {
  display: flex;
  justify-content: center;

  button {
    max-width: 400px;
  }
}

.div-button-doc {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-doc {
    max-width: 150px;
    padding: 10px 20px;
  }

  svg {
    height: 25px;
    margin-right: 10px;
  }

  button {
    display: flex;
    align-items: center;
  }
}

.memed-button {
  max-width: 300px;
}
