.chart {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: $teal1;
  position: relative;
  border-radius: 5px;
  margin: 10px;

  color: $gray6;

  pointer-events: none;

  .chart-text {
    position: absolute;

    font-weight: 500;

    span {
      font-size: 18px;
    }

    h2 {
      font-size: 25px;
    }
  }
}

.charts-biometrical {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .chart {
    display: inline-flex;
    max-width: 135px;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;

    .chart {
      max-width: 200px;
    }
  }
}
