.summary-max-height {
  overflow: auto;
  max-height: 500px;

  pre {
    max-width: 100px;
  }
}

.summary {
  display: flex;
  width: 100%;

  margin-bottom: 20px;

  .summary-date {
    color: $gray1;
    font-size: 16px;
    max-width: min-content;
    text-align: left;
    padding: 0 15px 20px 5px;
  }

  .summary-container {
    width: 100%;

    .summary-card {
      width: 100%;

      display: flex;

      .summary-body {
        background: $white1;

        color: $gray1;

        .description-fields {
          @apply space-y-1;
        }

        h3 {
          font-weight: 600;
          font-size: 16px;
        }

        p {
          font-size: 16px;
          word-break: break-word;
        }

        pre {
          font-size: 16px;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $primaryPink3;
          font-size: 16px;

          svg {
            font-size: 16px;
          }
        }

        .chip-div {
          @apply space-x-3 mt-1;
        }

        .all-data {
          text-align: center;
        }

        .open {
          display: inline;
        }

        .closed {
          visibility: hidden;
          height: 0;
        }

        .view-all {
          font-size: 22px;
          display: flex;
          justify-content: center;
          color: $primaryPink3;

          cursor: pointer;

          svg {
            fill: $primaryPink3;
            zoom: 1.2;
          }
        }

        .summary-exam {
          padding: 10px 0 0 10px;
          font-size: 16.5px;
        }
      }

      .files {
        padding: 0 10px;

        display: flex;
        flex-direction: column;

        .preview {
          display: flex;

          margin: 10px 10px 10px 0;

          .see-more {
            height: 100px;
            width: 100px;

            border-radius: 10px;

            padding: 0;

            position: relative;

            border: 1px solid lighten($gray3, 15%);
            border-radius: 10px;

            img {
              border-radius: 10px;

              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .check {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            height: 50%;
            width: 100%;

            border-radius: 10px;

            color: $white1;

            span {
              display: none;

              border: 1px solid $white1;
              padding: 0 10px;

              font-size: 20px;
            }

            transition: all 0.2s;

            &:hover {
              background: #303030a1;

              span {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}

.summary_anexos {
  margin-top: 1rem;
}
