.checked-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    zoom: 1.5;
    position: absolute;
    left: 2px;
    fill: $green3;
    margin-bottom: 13px;
  }

  .checked {
    zoom: 1.1;
    left: 8px;
    bottom: 12px;
  }
}
