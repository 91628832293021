.search-exames-container {
  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.7rem;
    color: $gray1;
  }

  .btn-div {
    margin: 0 4rem;
    text-align: center;

    button {
      padding: 5px 20px;
      width: 200px;
    }
  }

  .exams {
    margin-bottom: 20px;
    .add-page {
      display: flex;
      justify-content: center;

      margin: 10px 0;

      svg,
      span {
        cursor: pointer;
      }

      > div {
        display: flex;
        align-items: center;
      }

      span {
        color: $primaryPink3;
        font-size: 1.5rem;
        padding-left: 0.8rem;
      }
    }
  }

  .exam {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-weight: 500;
    color: $gray1;
    font-size: 16px;

    &:not(:last-child) {
      padding-bottom: 8px;
    }

    &:not(:first-child) {
      .trash-can {
        margin: 0 0 0 8px;
      }
    }

    label {
      font-size: 15px;
      text-align: center;
    }

    .trash-can {
      zoom: 1.1;
      cursor: pointer;
      fill: $gray3;

      margin: 22px 0 0 8px;

      &:hover {
        fill: $gray2;
      }
    }

    .select-exam {
      padding: 0;
      padding-right: 0.8rem;
      width: 100%;
    }

    .Select-value-label,
    .Select-option {
      white-space: pre-wrap;
    }

    .select-outside {
      padding: 0;
    }

    .input-outside {
      padding: 0;
    }

    .input-exam {
      padding-right: 0.4rem;
      width: 6rem;
      text-align: center;
    }

    input {
      padding-left: 0 !important;
    }

    div {
      margin: 0;
    }
  }

  .search-selected-items {
    margin: 1rem;
  }
}

@media (max-width: 1210px) {
  .select-exam {
    max-width: 650px !important;
  }
}

@media (max-width: 1158px) {
  .select-exam {
    max-width: 500px !important;
  }
}

@media (max-width: 780px) {
  .select-exam {
    max-width: 260px !important;
  }
}
