.doc-template {
  padding: 50px 100px;

  font-family: Arial, Helvetica, sans-serif;

  .doc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 15px 10px;
    border-bottom: 2px solid $gray1;

    .doc-info {
      display: flex;
      flex-direction: column;

      font-weight: 500;
    }

    img {
      height: 65px;
    }
  }

  .doc-body {
    min-height: 400px;
    padding: 15px;

    .doc-table {
      > div {
        margin-bottom: 30px;
      }

      table {
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
        }

        th,
        td {
          padding: 5px;
          text-align: left;
        }

        .exams {
          width: 100%;
        }
      }
    }
  }

  .doc-footer {
    text-align: center;
    padding: 8px 10px;
    border-top: 2px solid $gray1;
  }
}
