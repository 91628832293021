.site-content {
  width: 100%;
  height: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 0 10px;
}

.home {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 0;
}

.btns-change-medical-record {
  width: 100%;
  display: flex;

  a {
    width: 100%;
    padding: 0.5rem 0;
    background-color: #fff;
    text-align: center;

    font-size: 16px;
  }

  :first-child {
    border-radius: 0.4rem 0 0 0.4rem;
    border-left: none;
    border-right: 0.5px solid #eaeaea;
  }

  :last-child {
    border-radius: 0 0.4rem 0.4rem 0;
    border-right: none;
    border-left: 0.5px solid #eaeaea;
  }
}

.versatilis-medical-record-iframe {
  width: 100%;
  height: 768px;
  margin-top: 0.5rem;
}
