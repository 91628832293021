.group_select_filter {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  gap: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-style: normal;
  padding: 0 0 10px 20px;
}

.div_select_filter {
  background-color: rgb(242, 242, 242);
  height: 3rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.select_filter {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent
    url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  padding-right: 1.2rem;
  overflow: hidden;
}
