.modal-header {
  padding-top: 0;

  font-size: 25px;
}

.modal-body {
  font-size: 16px;

  min-width: 100px;
  max-width: 600px;

  max-height: 500px;
  overflow-y: scroll;

  p {
    margin-bottom: 14px;
  }
}

.allergy-modal {
  overflow-y: unset;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;

  button {
    padding: 5px 10px;
    margin: 0;
    max-width: 100px;
  }

  .modal-button {
    border-radius: 8px;
    margin-left: auto;
  }
}

.table {
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
  font-size: 15px;
  width: 100%;
  overflow: auto;

  margin-top: 15px;
  cursor: default;

  th {
    padding: 15px 35px;
    text-align: left;
    background: #ffffff;
    color: rgb(0, 0, 0, 0.6);
  }

  td {
    padding: 17px 40px;
  }

  tr {
    transition: all 0.25s ease;
    color: rgba(0, 0, 0, 0.6);
  }

  &,
  tbody tr:hover {
    background: rgba(25, 255, 197, 0.1);
    color: rgb(19, 160, 125);
  }
}
